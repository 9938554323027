(function(){

	angular
		.module('shelfApp')
		.controller('UserController', [
			'$rootScope' ,'$scope', '$mdSidenav', '$state', '$http', '$window', 'isLoggedin', '$mdDialog', '$mdPanel', '$mdToast', 'FileUploader','$stateParams', 'CartService', 'Settings', 'CollectionService',
			UserController
		])
		.controller('doShareController', ['$scope', '$mdDialog', 'photos','$http', '$mdToast', doShareController])
		.controller('CartController', ['CartService', '$mdDialog', '$mdSidenav', CartController])
		;

	function CartController (CartService, $mdDialog, $mdSidenav) {
		var self = this;

		self.hideNav = function () {
			$mdSidenav('right').toggle();
		};

		self.selection = CartService.cart;

		self.removeFromSelection = function (item) {
			CartService.removeItem(item);
		};

		self.removeAllFromSelection = function () {
			CartService.removeAll();
			self.selection = [];
		};

		self.doShare = doShare;

		function doShare (ev, photos) {
			$mdDialog.show({
				controller: 'doShareController',
				templateUrl: '/templates/share.html',
				parent: angular.element(document.body),
				locals : {photos: photos},
				targetEvent: ev,
				clickOutsideToClose:true
			});
		}
	}

	function UserController( $rootScope, $scope, $mdSidenav, $state, $http, $window, isLoggedin, $mdDialog, $mdPanel, $mdToast, FileUploader, $stateParams, CartService, Settings, CollectionService) {
		var self = this;

		$scope.$mdSidenav = $mdSidenav;

		self.settings = Settings;

		self.getSettings = function (name) {
            if (!self.settings) {return;}
            return self.settings.find(function (item) {
				return item.name == name;
			});
		};

		self.user = isLoggedin;

		self.toggleList = toggleList;
		self.toggleCart = toggleCart;
		self.login = login;
		self.doShare = doShare;
		self.logout = logout;
		self.selected = null;
		self.selectAll = selectAll;
		self.delete = showDeletePhotoConfirm;
		self.addAlbum = addAlbum;
		self.deleteAlbum = deleteAlbum;
		// self.createPDF = createPDF;
		self.showNewCollectionDialog = showNewCollectionDialog;
		self.moveToAlbum = moveToAlbum;
		self.existInCart = existInCart;
		self.getAlbum = getAlbum;
		self.currentAlbum = null;
		self.detailView = detailView;
		self.search = search;

		self.cart = CartService.cart;

		if($stateParams.collectionId){
			this.getAlbum($stateParams.collectionId);
		}else {
			this.getAlbum(false);
		}

		self.queueItemCount = function () {
			var total = 0;
			this.uploadQueue.forEach(function (collection) {
				total += collection.uploads.queue.length;
			});
			return total;
		};

		self.panel = function(ev) {
			var position = $mdPanel.newPanelPosition()
				.relativeTo('.demo-menu-open-button')
				.addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

			var config = {
				attachTo: angular.element(document.body),
				controller: function($mdPanel, uploads, $scope){
					console.log('hi im here');
					$scope.uploads = uploads;
				},
				controllerAs: 'ctrl',
				templateUrl: '/templates/upload_queue.html',
				panelClass: 'demo-menu-example',
				position: position,
				locals: {
					'uploads': this.uploadQueue
				},
				openFrom: ev,
				clickOutsideToClose: true,
				escapeToClose: true,
				focusOnOpen: false,
				zIndex: 2
			};

			$mdPanel.open(config);
		};

		self.renamePanel = function(ev, photo) {
			var position = $mdPanel.newPanelPosition()
				.relativeTo(ev.target)
				.addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS);

			var config = {
				attachTo: angular.element(document.body),
				controller: function(mdPanelRef, photo, $scope, $http){
					$scope.newName = photo.name;

					$scope.doSubmit = function (name) {
						$http.put('/api/photos/'+photo.id, {name: name}).then(function (res) {
							$mdToast.show($mdToast.simple().content('Photo Renamed'));
							mdPanelRef.close();
						}, function (err) {
							$mdToast.show($mdToast.simple().content('Failed to Add Photos to Album'));
							console.log('err:' , err);
						});
					};
				},
				hasBackdrop: true,
				controllerAs: 'ctrl',
				templateUrl: '/templates/rename.html',
				panelClass: 'demo-menu-example',
				position: position,
				locals: {
					'photo': photo
				},
				openFrom: ev,
				clickOutsideToClose: true,
				escapeToClose: true,
				focusOnOpen: false,
				zIndex: 2
			};

			$mdPanel.open(config);
		};

		self.uploadQueue = [];

		self.getPendingUploads = getPendingUploads;

		getCollections ();

		function getPendingUploads (id) {
			return self.uploadQueue.find(function(it){
					return it.id === id;
				});
		}

		function existInCart (item) {
			CartService.toggleItem(item);
		}

		function search(query) {
			if (query.length) {
				$scope.searchMode = true;
				$http.get('/api/photos/?query='+query).then(function (res) {
					self.results = res.data;
				}, function (err) {
					console.log('err:' , err);
				});
			} else {
				$scope.searchMode = false;
				self.results = [];
			}
		}

		function moveToAlbum (ev, photos) {
			$mdDialog.show({
				controller: function ($scope, $mdDialog, albums) {
					$scope.albums = albums;
					$scope.onItemSelect = function(id) {
						$mdDialog.hide(id);
					};
					$scope.cancel = function () {
						$mdDialog.cancel();
					};
				},
				templateUrl: '/templates/movetoalbum.html',
				parent: angular.element(document.body),
				locals : {albums: CollectionService.collection},
				targetEvent: ev,
				clickOutsideToClose:true
			}).then(function(albumId) {
				photos.forEach(function (photo) {
					$http.put('/api/photos/'+photo.id, {album_id: albumId}).then(function (res) {
						self.getAlbum($stateParams.collectionId);
					}, function (err) {
						console.log('err:' , err);
					});
				});
			});
		}

		function detailView (ev, item) {
			$mdDialog.show({
				controller: function ($scope, $mdDialog, item) {
					$scope.file = item;

					var re = /(?:\.([^.]+))?$/;
					switch(re.exec($scope.file.file)[1]){
						case 'jpeg':
						case 'jpg':
						case 'png':
						case 'gif':
							$scope.type = 'image';
							break;
						case 'pdf':
							$scope.type = 'pdf';
							break;
                        case 'mp4':
                            $scope.type = 'mp4';
                            break;
						default:
							$scope.type = 'unknown';
					}

					$scope.onItemSelect = function(id) {
						$mdDialog.hide(id);
					};
					$scope.cancel = function () {
						$mdDialog.cancel();
					};
				},
				templateUrl: '/templates/detail.html',
				parent: angular.element(document.body),
				locals : {item: item},
				targetEvent: ev,
				clickOutsideToClose:true
			});
		}

		function deleteAlbum (ev, albums) {
			var confirm = $mdDialog.confirm()
				.parent(angular.element(document.body))
				.title('Would you like to delete the albums?')
				.content('All the photos in the albums will be deleted')
				.ariaLabel('Delete Albums')
				.ok('Delete!')
				.cancel('Cancel')
				.targetEvent(ev);
			$mdDialog.show(confirm).then(function() {
				albums.forEach(function(albm){
					$http.delete('/api/albums/'+albm.id).then(function (res) {
						$mdToast.show($mdToast.simple().content('Albums Deleted'));
						getCollections();
					}, function (err) {
						$mdToast.show($mdToast.simple().content('Failed to Delete Albums, Album is probably not empty'));
						console.log('err:' , err);
					});
				});

			});
		}

		function showNewCollectionDialog(ev) {
			var confirm = $mdDialog.prompt()
			.title('Add New Collection?')
			.textContent('Enter name of new collection.')
			.placeholder('Collection Name')
			.ariaLabel('Collection Name')
			.initialValue('')
			.targetEvent(ev)
			.ok('Add!')
			.cancel('Cancel');

			$mdDialog.show(confirm).then(function(result) {
				addAlbum(result);
			});
		}

		function showDeletePhotoConfirm(ev, items) {
			var confirm = $mdDialog.confirm()
				.title('Delete Selected Photos?')
				.textContent('Are you sure you want to delete selected items?')
				.ariaLabel('Delete')
				.targetEvent(ev)
				.theme('md-warn')
				.ok('Yes')
				.cancel('Cancel');

			$mdDialog.show(confirm).then(function() {
				deletePhotos(items);
			});
		}

		function addAlbum (name) {
			$http.post('/api/albums', {name: name}).then(function (res) {
				$mdToast.show($mdToast.simple().content('Album '+name +' Added'));
				getCollections();
				getAlbum(res.data.id);
			}, ErrorHandler);
		}

		function getCollections () {
			$http.get('/api/albums?all=true').then(function (res) {
                CollectionService.updateColletion(res.data);
                console.log('CollectioService.collections', CollectionService.collection);
				self.collections = CollectionService.collection;
			});
		}

		function getAlbum ($id) {
			var url = !$id ? '/api/albums/': '/api/albums/'+$id;
			$http.get(url).then(function (res) {
				var albums = res.data;
				self.currentAlbum = $id;

				albums.forEach(function(item){
					item.photos.forEach(function (photo) {
						var photoExist = self.cart.find(CartService.itemExist.bind(photo));
						if (photoExist) {
							photo.selected = true;
						}
					});

					var isInArray = self.uploadQueue.find(function(it){
						return it.id === item.id;
					});

					if (!isInArray) {

						var uploader = new FileUploader({
									url: '/api/albums/'+item.id+'/upload',
									removeAfterUpload : true,
									headers: {
										Authorization : 'Bearer '+ $window.sessionStorage.token
									}
								});

						uploader.onProgressItem = function(fileItem, progress) {
							console.info('onProgressItem', fileItem, progress);
						};

						uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
							console.info('onWhenAddingFileFailed', item, filter, options);
						};
						uploader.onAfterAddingFile = function(fileItem) {
							console.info('onAfterAddingFile', fileItem);
							fileItem.upload();
						};
						uploader.onAfterAddingAll = function(addedFileItems) {
							console.info('onAfterAddingAll', addedFileItems);
						};
						uploader.onBeforeUploadItem = function(item) {
							console.info('onBeforeUploadItem', item);
						};
						uploader.onProgressItem = function(fileItem, progress) {
							console.info('onProgressItem', fileItem, progress);
						};
						uploader.onProgressAll = function(progress) {
							console.info('onProgressAll', progress);
						};
						uploader.onSuccessItem = function(fileItem, response, status, headers) {
							console.info('onSuccessItem', fileItem, response, status, headers);
							getAlbum(self.currentAlbum);
						};
						uploader.onErrorItem = function(fileItem, response, status, headers) {
							console.info('onErrorItem', fileItem, response, status, headers);
                            $mdToast.show($mdToast.simple().content('Failed to Upload File'));
						};
						uploader.onCancelItem = function(fileItem, response, status, headers) {
							console.info('onCancelItem', fileItem, response, status, headers);
						};
						uploader.onCompleteItem = function(fileItem, response, status, headers) {
							console.info('onCompleteItem', fileItem, response, status, headers);
						};
						uploader.onCompleteAll = function() {
							console.info('onCompleteAll');
						};

						self.uploadQueue.push(
							{
								id: item.id,
								uploader: uploader
							}
						);

					}

				});
                CollectionService.updateAlbums(albums);
				self.albums = CollectionService.albums;
                $scope.$emit('someEvent', [1,2,3]);
			}, function (err) {
				$mdToast.show($mdToast.simple().content('Failed to Load Albums'));
				console.log('err:' , err);
			});

		}

		function deletePhotos (items) {
			items.forEach(function(item){
				$http.delete('/api/photos/'+item.id).then(function (res) {
					$mdToast.show($mdToast.simple().content('Photos Deleted'));
                    CartService.removeItem(item);
					getAlbum(self.currentAlbum);
				}, function (err) {
                    $mdToast.show($mdToast.simple().content(err.data.message || 'Unable to Delete Files'));
				});
			});
		}

		function selectAll (photos, deselect) {
			photos.forEach(function (photo) {
				if (deselect) {
					photo.selected = false;
					if(!photo.selected) {CartService.removeItem(photo);}
				}else{
					photo.selected = true;
					if(photo.selected) {CartService.toggleItem(photo);}
				}
			});
		}

		function logout () {
			$window.sessionStorage.token = false;
			$state.go('login');
		}

		function doShare (ev, photos) {
			$mdDialog.show({
				controller: 'doShareController',
				templateUrl: '/templates/share.html',
				parent: angular.element(document.body),
				locals : {photos: photos},
				targetEvent: ev,
				clickOutsideToClose:true
			});
		}

		function login () {
			$http.get('/server/login').then(function (res) {
				$window.sessionStorage.token = res.data.token;
			});
		}

		function toggleList () {
			$mdSidenav('left').toggle();
		}

		function toggleCart () {
			$mdSidenav('right').toggle();
		}

		function ErrorHandler (res) {
			if(res.status === 422){
				if(res.data.name){
					$mdToast.show($mdToast.simple().content(res.data.name));
				}
				else{
					$mdToast.show($mdToast.simple().content('Failed'));

				}
			}else {
				$mdToast.show($mdToast.simple().content('Failed'));
			}
		}

	}


	function doShareController($scope, $mdDialog, photos, $http, $mdToast) {
		$scope.photos = photos;
		$scope.sizes = [
			{name : 'Small' ,text : '200', code: 'thumbnails'},
			{name : 'Original' ,text : '', selected: true, code: 'original'}
		];
		$scope.cancel = function () {
			$mdDialog.hide();
		};
		$scope.sendEmail = function (email, sizes) {
			downloadZip(sizes, email);
		};

		$scope.downloadZip = downloadZip;
		$scope.zipGenerated = null;
		$scope.showGenerating = false;

		function downloadZip (sizes, isEmail) {
			var itemIds = [];
			photos.forEach(function (item) {
				itemIds.push(item.id);
			});
			$scope.showGenerating = true;
			var data = {};
			if (isEmail) {
				data = {photos : itemIds, sizes : sizes, email: isEmail};
			}else {
				data = {photos : itemIds, sizes : sizes};
			}
			$http.post(
				'/api/photos/download',
				data
			).then(function (res) {
				if (!isEmail) {
					var win = window.open(res.data.location, '_blank');
					win.focus();
				} else {
					$mdToast.show($mdToast.simple().content('Email Sent'));
				}
				$scope.showGenerating = false;
				$mdDialog.hide();
			}, function (err) {
				$scope.showGenerating = false;
				console.log('err :',err);
			});
		}
	}

})();
