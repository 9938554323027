(function(){

	angular
		.module('shelfApp')
		.factory('CartService', [
			'$q', 'localStorageService',
			CartService
		])
        .factory('CollectionService', [
            '$q', 'localStorageService',
            CollectionService
        ])
        .factory('debounce', ['$timeout','$q', Debounce]);

	function CartService ($q, localStorageService) {
		var cartRef = {};
		if(!(localStorageService.get('cart') && localStorageService.get('cart').length)) {
			localStorageService.set('cart', []);
		}
		cartRef.cart = localStorageService.get('cart');

		function getItems() {
			return cartRef.cart;
		}

		function itemExist(element) {
			return element.id == this.id;
		}

		function addItem(item) {
			if(!cartRef.cart.find(itemExist.bind(item))){
				cartRef.cart.push(item);
				localStorageService.set('cart', cartRef.cart);
			}
			return cartRef.cart;
		}

		function toggleItem(item) {
			if(!cartRef.cart.find(itemExist.bind(item))){
				cartRef.cart.push(item);
				localStorageService.set('cart', cartRef.cart);
			}else {
				removeItem(item);
			}
			return cartRef.cart;
		}

		function getCount() {
			return cartRef.cart.length;
		}

		function removeItem(item) {
			var index = cartRef.cart.findIndex(itemExist.bind(item));
			if(index > -1){
				cartRef.cart.splice(index, 1);
				localStorageService.set('cart', cartRef.cart);
			}
			return cartRef.cart;
		}

		function removeAll() {
			cartRef.cart = [];
			localStorageService.set('cart', cartRef.cart);
			return cartRef.cart;
		}

		cartRef.itemExist = itemExist;
		cartRef.getItems = getItems;
		cartRef.removeItem = removeItem;
		cartRef.removeAll = removeAll;
		cartRef.addItem = addItem;
		cartRef.count = cartRef.cart.length;
		cartRef.toggleItem = toggleItem;

		return cartRef;

	}

    function Debounce($timeout, $q) {
        return function debounce(func, wait, immediate) {
            var timeout;
            var deferred = $q.defer();
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if(!immediate) {
                        deferred.resolve(func.apply(context, args));
                        deferred = $q.defer();
                    }
                };
                var callNow = immediate && !timeout;
                if ( timeout ) {
                    $timeout.cancel(timeout);
                }
                timeout = $timeout(later, wait);
                if (callNow) {
                    deferred.resolve(func.apply(context,args));
                    deferred = $q.defer();
                }
                return deferred.promise;
            };
        };
    }

    function CollectionService() {
		var _col = {
			collection : [],
			albums : []
		};

		function updateColletion(items) {
            _col.collection = items;
        }

        function updateAlbums(items) {
            _col.albums = items;
        }

        _col.updateColletion = updateColletion;
        _col.updateAlbums = updateAlbums;
		return _col;
    }

})();
