(function(){
	'use strict';
	angular
	.module('shelfApp')
	.directive('details',  details);

	function details() {
		return {
			restrict: 'E',
			scope : {
				file: '='
			},
			replace:true,
			controller: function($scope, $element, $attrs, $location) {
				var re = /(?:\.([^.]+))?$/;
				switch(re.exec($scope.file.file)[1]){
					case 'jpeg':
					case 'jpg':
					case 'png':
					case 'gif':
						$scope.type = 'image';
						break;
					// case 'pdf':
					// 	$scope.type = 'pdf';
					// 	break;
					default:
						$scope.type = 'unknown';
				}

				$scope.onPageLoad = function(page) {
					console.log('loaded');
					return true;
				};
			},
			template: '<div class="detail-container" style="background-image: url({{thumbnail}})"></div>'
		};
	}

})();
