(function () {
    angular
        .module('shelfApp')
        .controller('SettingsController', [
            '$http', '$mdDialog', 'FileUploader', '$window', '$mdToast',
            SettingsController
        ]);

    function SettingsController($http, $mdDialog, FileUploader, $window, $mdToast) {
        var self = this;

        self.showAddUser = showAddUser;

        loadUsers();

        $http.get('/settings').then(function (res) {
            self.settings = res.data.settings;
        });
        function loadUsers() {
            $http.get('/api/users').then(function (response) {
                self.users = response.data;
            });
        }

        self.uploader = new FileUploader({
            url: '/api/settings?name=logo',
            removeAfterUpload : true,
            headers: {
                Authorization : 'Bearer '+ $window.sessionStorage.token
            }
        });

        self.uploader.onAfterAddingFile = function(fileItem) {
            fileItem.upload();
        };

        self.uploader.onSuccessItem = function(fileItem, response, status, headers) {
            $mdToast.show($mdToast.simple().content('Logo Updated'));
        };

        //Background uploader; too lazy to make it dynamic.. Zzz
        self.bg_uploader = new FileUploader({
            url: '/api/settings?name=background',
            removeAfterUpload : true,
            headers: {
                Authorization : 'Bearer '+ $window.sessionStorage.token
            }
        });

        self.bg_uploader.onAfterAddingFile = function(fileItem) {
            fileItem.upload();
        };

        self.bg_uploader.onSuccessItem = function(fileItem, response, status, headers) {
            $mdToast.show($mdToast.simple().content('Background Updated'));
        };


        function showAddUser(ev) {
            $mdDialog.show({
                controller: function ($scope, $http, $mdDialog) {
                    $scope.save = function (data) {
                        $http.post('api/create', data).then(function () {
                            $mdDialog.hide();
                        }, function () {
                            $mdDialog.hide();
                        });
                    };
                },
                templateUrl: '/templates/new_user.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true
            }).then(function () {
                loadUsers();
            });

        }

        self.getSettings = function (name) {
            if (!self.settings) {
                return;
            }
            return self.settings.find(function (item) {
                return item.name == name;
            });
        };

    }

})();
