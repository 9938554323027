(function(){
    'use strict';
    angular
    .module('shelfApp', ['ngMaterial', 'ui.router', 'LocalStorageModule', 'angularFileUpload', 'pdfjsViewer'])
    .factory('authInterceptor', function ($rootScope, $q, $window, $location) {
        return {
            request: function (config) {
                config.headers = config.headers || {};
                if ($window.sessionStorage.token) {
                    config.headers.Authorization = 'Bearer '+$window.sessionStorage.token;
                }
                return config;
            },
            response: function (response) {
                if (response.status === 401) {
                    console.log('401 found');
                    $window.sessionStorage.token = false;
                    $location.url('/login');
                    // $window.location.reload();
                    // handle the case where the user is not authenticated
                }
                return response || $q.when(response);
            },
            responseError: function(rejection) {
                console.log('error with request');
                if(rejection.status == 401){
                    $window.sessionStorage.token = false;
                    $location.url('/login');
                    // $window.location.reload();
                }
                return $q.reject(rejection);
            }
        };
    })
    .directive('myDragenter', function (debounce) {
        return {
            scope: {
                myDragenter : '&'
            },
            link: function (scope, element, attrs) {
                element.attr('draggable', 'true');
                element.on('dragenter', debounce(doDragenter, 300, false));

                function doDragenter() {
                    element.children().addClass("child-elements");
                    scope.$parent.$eval(attrs.myDragenter);
                    scope.$parent.$apply();
                }

                element.on('dragleave', debounce(dragleave, 300, false) );

                function dragleave() {
                    element.children().removeClass("child-elements");
                }
            }
        };
    })
    .config(function($mdThemingProvider, $mdIconProvider, $urlRouterProvider, $stateProvider, $httpProvider, localStorageServiceProvider, pdfjsViewerConfigProvider){
        localStorageServiceProvider
            .setPrefix('shelf')
            .setStorageType('localStorage');
        $httpProvider.interceptors.push('authInterceptor');

        $mdThemingProvider.theme('default')
            .primaryPalette('teal')
            .accentPalette('deep-purple');

        $mdThemingProvider.theme('shelf-dark', 'default')
            .primaryPalette('cyan')
            .dark();

        $urlRouterProvider.otherwise('/main/home/');

        $stateProvider
        .state('main', {
            url: '/main',
            abstract:true,
            templateUrl: '/templates/main.html',
            resolve : {
                isLoggedin : function($window, $http, $state){
                    return $http.get('/api/is_authenticated').then(function (res) {
                        return res.data.user;
                    }, function (error) {
                        console.log('error', error);
                        $window.sessionStorage.token = false;
                        $state.go('login');
                        return false;
                    });
                },
                Settings : function ($http) {
                    return $http.get('/settings').then(function (res) {
                        return res.data.settings;
                    });
                }
            },
            controller: 'UserController',
            controllerAs : 'ul'
        })
        .state('main.settings', {
            url: '/settings',
            templateUrl: '/templates/settings.html',
            resolve : {
                isLoggedin : function($window, $http, $state){
                    return $http.get('/api/is_authenticated').then(function (res) {
                        return res.data.user;
                    }, function (error) {
                        console.log('error', error);
                        $window.sessionStorage.token = false;
                        $state.go('login');
                        return false;
                    });
                }
            },
            controller: 'SettingsController',
            controllerAs : 'ul'
        })
        .state('main.home', {
            url: '/home/{collectionId}',
            templateUrl: '/templates/home.html',
            resolve : {
                isLoggedin : function($window, $http, $state){
                    return $http.get('/api/is_authenticated').then(function (res) {
                        return res.data.user;
                    }, function (error) {
                        console.log('error', error);
                        $window.sessionStorage.token = false;
                        $state.go('login');
                        return false;
                    });
                }
            },
            controller: 'UserController',
            controllerAs : 'ul'
        })
        .state('main.cart', {
            url: '/cart',
            templateUrl: '/templates/cart.html',
            resolve : {
                isLoggedin : function($window, $http, $state){
                    return $http.get('/api/is_authenticated').then(function (res) {
                        return res.data.user;
                    }, function (error) {
                        console.log('error', error);
                        $window.sessionStorage.token = false;
                        $state.go('login');
                        return false;
                    });
                }
            },
            controller: 'CartController',
            controllerAs : 'ul'
        })
        // .state('main.createpdf', {
        //     url: '/createpdf',
        //     templateUrl: '/src/templates/createpdf.html',
        //     resolve : {
        //         isLoggedin : function($window, $http, $state){
        //             return $http.get('/server/secure/isLoggedin').then(function (res) {
        //                 return true;
        //             }, function (error) {
        //                 console.log('error', error);
        //                 $window.sessionStorage.token = false;
        //                 $state.go('login');
        //                 return false;
        //             });
        //         },
        //         cart : function ($window, $state) {
        //             if(!$window.sessionStorage['shelf.cart'].lenth){
        //                 $state.go('main');
        //                 return;
        //             }
        //             return $window.sessionStorage['shelf.cart'];
        //         }
        //     },
        //     controller: 'UserController',
        //     controllerAs : 'ul'
        // })
        .state('login', {
            url: '/login',
            templateUrl: '/templates/login.html',
            resolve : {
                Settings : function ($http) {
                    return $http.get('/settings').then(function (res) {
                        return res.data.settings;
                    });
                }
            },
            controller: function($scope, $mdDialog, $http, $mdToast, $state, $window, Settings) {
				$scope.settings = Settings;
				$scope.getSettings = function (name) {
					if (!$scope.settings) {return;}
					return $scope.settings.find(function (item) {
						return item.name == name;
					});
				};
                $scope.doLogin = function () {
                    $http.post('/api/login', $scope.user)
                        .then(function (res) {
                            $mdDialog.hide();
                            $window.sessionStorage.token = res.data.token;
                            $mdToast.show($mdToast.simple().content('Welcome Back!'));
                            $state.go('main.home', {collectionId:''});
                        }, function (error) {
                            $mdToast.show($mdToast.simple().content('Invalid Login Credentials'));
                        });
                };
            }
        });
    });

})();
