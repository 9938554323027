(function(){
	'use strict';
	angular
	.module('shelfApp')
	.directive('iconise',  iconise);

	function iconise() {
		return {
			restrict: 'E',
			scope : {
				file: '='
			},
			replace:true,
			controller: function($scope, $element, $attrs, $location) {
				var re = /(?:\.([^.]+))?$/;
				switch(re.exec($scope.file.file)[1]){
					case 'jpeg':
					case 'jpg':
					case 'png':
					case 'gif':
						$scope.thumbnail = '/images/thumbnails/'+$scope.file.file;
						break;
					case 'gif':
						$scope.thumbnail = '/images/svg/gif.svg';
						break;
					case 'pdf':
						$scope.thumbnail = '/images/svg/pdf.svg';
						break;
					case 'cdr':
						$scope.thumbnail = '/images/svg/cdr.svg';
						break;
					case 'mp3':
						$scope.thumbnail = '/images/svg/mp3.svg';
						break;
					case 'eps':
						$scope.thumbnail = '/images/svg/eps.svg';
						break;
                    case 'mov':
                    case 'mp4':
						$scope.thumbnail = '/images/svg/mov.svg';
						break;
					case 'psd':
						$scope.thumbnail = '/images/svg/ps.svg';
						break;
					case 'ai':
						$scope.thumbnail = '/images/svg/ai.svg';
						break;
					default:
						$scope.thumbnail = '/images/svg/unknown.svg';
				}
			},
			template: '<div class="image-container" style="background-image: url({{thumbnail}})"></div>'
		};
	}

})();
